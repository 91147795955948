<!-- 考生成绩表更多 -->
<template>
    <div class="ResultMore">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">考生成绩表</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <div class="" >
           <button class="btn" type="button" v-for="(item,index) in tabBtn" :key="index"
            @click="toTab(index)" :class="tab==index?'active':''">
               {{item}}
           </button>
        </div>
        <!-- <el-divider></el-divider> -->
        <!-- 成绩表 -->
        <div class="" v-if="tab==0" :key="0">
            <!-- 表格 -->
            <el-table :data="resultData" border stripe style="width:97.9%;flex: 1;"  height="660">
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="certificate_no" label="准考证号" align="center" width="150">
                </el-table-column>
                <el-table-column prop="name" label="学员姓名" align="center" width="100">
                </el-table-column>
                <el-table-column prop="english_name" label="拼音名" align="center" width="150">
                </el-table-column>
                <el-table-column prop="org_name" label="提供机构" align="center" width="200">
                </el-table-column>
                <el-table-column prop="course" label="考试科目" align="center" width="200">
                </el-table-column>
                <el-table-column prop="original_level" label="原等级" align="center" width="100">
                </el-table-column>
                <el-table-column prop="exam_level" label="报考等级" align="center" width="150">
                </el-table-column>
                <el-table-column prop="remark" label="考试备注" align="center" width="200">
                </el-table-column>
                <el-table-column prop="text_score" label="成绩" align="center" width="100">
                </el-table-column>
                <el-table-column prop="exam_result" label="成绩等级" align="center" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.exam_result==1">-</span>
                        <span v-else-if="scope.row.exam_result==2">
                            {{ scope.row.exam_level }}
                        </span>
                        <span v-else-if="scope.row.exam_result==3">
                            {{ scope.row.original_level }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="exam2_date" label="考试日期" align="center" width="100">
                </el-table-column>
                <el-table-column prop="exam_startdate" label="开始时间" align="center" width="150">
                </el-table-column>
                <el-table-column prop="exam_enddate" label="结束时间" align="center" width="150">
                </el-table-column>
                <el-table-column prop="enroll_price" label="考试费" align="center" width="120">
                </el-table-column>
            </el-table> 
        </div>
        <!-- 缺考表 -->
        <div class="" v-if="tab==1" :key="1">
            <!-- 表格 -->
            <el-table :data="lackData" border stripe style="width:97.9%;flex: 1;"  height="660">
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="certificate_no" label="准考证号" align="center" width="150">
                </el-table-column>
                <el-table-column prop="name" label="学员姓名" align="center" width="100">
                </el-table-column>
                <el-table-column prop="english_name" label="拼音名" align="center" width="150">
                </el-table-column>
                <el-table-column prop="gender" label="性别" align="center" width="80">
                    <template slot-scope="scope">
                        <span v-if="scope.row.gender==1">男</span>
                        <span v-else-if="scope.row.gender==2">女</span>
                    </template>
                </el-table-column>
                <el-table-column prop="nation2" label="国籍" align="center" width="100">
                </el-table-column>
                <el-table-column prop="nation" label="民族" align="center" width="100">
                </el-table-column>
                <el-table-column prop="birthday" label="出生日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="course" label="学科" align="center" width="150">
                </el-table-column>
                <el-table-column prop="exam_level" label="等级" align="center" width="120">
                </el-table-column>
                <el-table-column prop="remark" label="说明" align="center" width="300">
                </el-table-column>
                <el-table-column prop="" label="安排单号" align="center" width="150">
                </el-table-column>
            </el-table> 
        </div>
        <!-- 报名规则 -->
        <div class="" style="margin-left: 22px;" v-if="tab==2" :key="2">
            <p style="font-size: 22px;font-weight:bold;margin-bottom: 20px;">报名规则</p>
            <div class="" style="font-size: 16px;line-height:25px;color: #666;">
                <p>1.报考等级不能小于原等级;</p>
                <p>2.原等级为无等级,报考等级不能高于 1 级以上;</p>
                <p>3.报考等级不能高于原等级两级以上;</p>
                <p>4.报考等级 0 级及以上,不能跳级报考。</p>
            </div>
        </div>
        <!-- 国美格式报名表 -->
        <div class="" v-if="tab==3" :key="3">
            <el-table :data="gomeData" border stripe style="width:97.9%;flex: 1;"   height="660">
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="name" label="姓名" align="center" width="120">
                </el-table-column>
                <el-table-column prop="english_name" label="拼音名" align="center" width="150">
                </el-table-column>
                <el-table-column prop="idcard" label="身份证号码" align="center" width="200">
                </el-table-column>
                <el-table-column prop="gender" label="性别" align="center" width="80">
                    <template slot-scope="scope">
                        <span v-if="scope.row.gender==1">男</span>
                        <span v-else-if="scope.row.gender==2">女</span>
                    </template>
                </el-table-column>
                <el-table-column prop="birthday" label="出生年月日" align="center" width="200">
                </el-table-column>
                <el-table-column prop="course" label="报考专业" align="center" width="150">
                </el-table-column>
                <el-table-column prop="nation" label="民族" align="center" width="150">
                </el-table-column>
                <el-table-column prop="text_score" label="成绩" align="center" width="150">
                </el-table-column>
                <el-table-column prop="nation2" label="国籍" align="center" width="150">
                </el-table-column>
                <el-table-column prop="original_level" label="原级别" align="center" width="150">
                </el-table-column>
                <el-table-column prop="is_deputy" label="是否做副证" align="center" width="150">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_deputy==0">否</span>
                        <span v-else-if="scope.row.is_deputy==1">是</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 收款表 -->
        <div class="" v-if="tab==4" :key="4">
            <el-table :data="collection" border stripe style="width:1201px;flex: 1;" height="660">
                <!-- 复选按钮 -->
                <el-table-column type="selection" align="center" width="50">
                </el-table-column>
                <el-table-column prop="" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" label="状态" align="center" width="100">
                </el-table-column>
                <el-table-column prop="" label="回款日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="考试费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="业务经办" align="center" width="200">
                </el-table-column>
                <el-table-column prop="" label="业务类型" align="center" width="200">
                </el-table-column>
                <el-table-column prop="" label="摘要" align="center" width="300">
                </el-table-column>
            </el-table>
        </div>
        <!-- 异常身份证号表 -->
        <div class="" v-if="tab==5" :key="5">
            <el-table :data="IdData" border stripe style="width:97.9%;flex: 1;" height="660">
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="name" label="姓名" align="center" width="100">
                </el-table-column>
                <el-table-column prop="phone" label="考生电话" align="center" width="100">
                </el-table-column>
                <el-table-column prop="idcard" label="身份证号码" align="center" width="200">
                </el-table-column>
                <el-table-column prop="english_name" label="拼音名" align="center" width="120">
                </el-table-column>
                <el-table-column prop="gender" label="性别" align="center" width="80">
                    <template slot-scope="scope">
                        <span v-if="scope.row.gender==1">男</span>
                        <span v-else-if="scope.row.gender==2">女</span>
                    </template>
                </el-table-column>
                <el-table-column prop="birthday" label="出生年月日" align="center" width="150">
                </el-table-column>
                <el-table-column prop="course" label="报考专业" align="center" width="120">
                </el-table-column>
                <el-table-column prop="exam_level" label="报考级别" align="center" width="120">
                </el-table-column>
                <el-table-column prop="org_name" label="所属机构" align="center" width="120">
                </el-table-column>
                <el-table-column prop="mobile" label="机构电话" align="center" width="120">
                </el-table-column>
                <el-table-column prop="nation" label="民族" align="center" width="80">
                </el-table-column>
                <el-table-column prop="text_score" label="成绩" align="center" width="100">
                </el-table-column>
                <el-table-column prop="nation2" label="国籍" align="center" width="100">
                </el-table-column>
                <el-table-column prop="original_level" label="原级别" align="center" width="100">
                </el-table-column>
                <el-table-column prop="is_deputy" label="是否做副证" align="center" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_deputy==0">否</span>
                        <span v-else-if="scope.row.is_deputy==1">是</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 异常报名表 -->
        <div class="" v-if="tab==6" :key="6">
            <el-table :data="abnormalData" border stripe style="width:97.9%;flex: 1;" height="660">
                <!-- 复选按钮 -->
                <el-table-column type="selection" align="center" width="50">
                </el-table-column>
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="name" label="姓名" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="考生电话" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="身份证号码" align="center" width="200">
                </el-table-column>
                <el-table-column prop="" label="拼音名" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="性别" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" label="报考专业" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="报考级别" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="原级别-A" align="center"  width="120">
                </el-table-column>
                <el-table-column prop="" label="原级别-B" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="机构电话" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="民族" align="center" width="100">
                </el-table-column>
                <el-table-column prop="" label="成绩" align="center" width="100">
                </el-table-column>
                <el-table-column prop="" label="国籍" align="center" width="100">
                </el-table-column>
                <el-table-column prop="" label="是否做副证" align="center" width="120">
                </el-table-column>
            </el-table>
        </div>
        <!-- 底部 -->
        <div class="" style="display: flex;justify-content: space-between;">
           <div class=""  style="margin-left: 22px;" v-if="tab==1">
               缺考人数：<input type="text" class="ipt" :value="lackSun" disabled />
           </div>
            <!--分页 -->
            <el-pagination @size-change="handleSizeChange" 
                @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="10" background
                layout="total, sizes, prev, pager, next, jumper" :total="total" v-if="tab!==2">
            </el-pagination>
           </div>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               tabBtn:["成绩表","缺考表","报名规则","国美格式报名表","收款表","异常身份证号表","异常报名表"],
               tab:0,//代表下标 
               resultData:[],//成绩表
               lackData:[],//缺考表
               lackSun:"",//缺考人数
               gomeData:[],//国美格式报名表
               collection:[],//收款表
               IdData:[],//异常身份证
               abnormalData:[],//异常报名
               currentPage: 1,
               currentLimit: 20, //条数
               total: 0,
            }
        },
        created() {
             this.getList()
        },
        methods:{
            goBack() { //返回
                this.$router.push({
                    path: "examineeResult"
                })
            }, 
            toTab(index){
                this.tab=index
                this.getList()
            },
            getList(){
                if(this.tab==0){//成绩表
                    this.$request({
                        url:'/api/examenroll/scores',
                        method:'POST',
                        data:{
                           limit:this.currentLimit, 
                           page:this.currentPage
                        }
                    }).then(res=>{
                        // console.log('成绩表',res)
                        if(res.code==1){
                            this.resultData=res.data.list
                            this.total=res.data.count
                        }
                    })
                }else if(this.tab==1){//缺考表
                    this.$request({
                        url:'/api/examenroll/missExam',
                        method:'POST',
                        data:{
                           limit:this.currentLimit, 
                           page:this.currentPage
                        }
                    }).then(res=>{
                        console.log('缺考表',res)
                        if(res.code==1){
                            this.lackData=res.data.list
                            this.total=res.data.count
                            this.missexam=res.data.lackSun
                        }
                        
                    })
                }else if(this.tab==3){//国美格式报名表
                    this.$request({
                        url:'/api/examenroll/missExam',
                        method:'POST',
                        data:{
                           limit:this.currentLimit, 
                           page:this.currentPage
                        }
                    }).then(res=>{
                        // console.log('国美格式报名表',res)
                        if(res.code==1){
                            this.gomeData=res.data.list
                            this.total=res.data.count
                        }
                        
                    })
                }else if(this.tab==4){//收款表
                    // this.$request({
                    //     url:'',
                    //     method:'POST',
                    //     data:{
                    //        limit:this.currentLimit, 
                    //        page:this.currentPage
                    //     }
                    // }).then(res=>{
                    //     console.log('收款表',res)
                    //     if(res.code==1){
                    //         this.collection=res.data.list
                    //         this.total=res.data.count
                    //     }
                    // })
                }else if(this.tab==5){//异常身份证号表
                    this.$request({
                        url:'/api/examenroll/errorIDcard',
                        method:'POST',
                        data:{
                           limit:this.currentLimit, 
                           page:this.currentPage
                        }
                    }).then(res=>{
                        console.log('异常身份证号表',res)
                        if(res.code==1){
                            this.IdData=res.data.list
                            this.total=res.data.count
                        }
                        
                    })
                }else if(this.tab==6){//异常报名表
                    // this.$request({
                    //     url:'',
                    //     method:'POST',
                    //     data:{
                    //        limit:this.currentLimit, 
                    //        page:this.currentPage
                    //     }
                    // }).then(res=>{
                    //     console.log('异常报名表',res)
                    //     if(res.code==1){
                    //         this.abnormalData=res.data.list
                    //         this.total=res.data.count
                    //     }
                        
                    // })
                }
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage=val
                this.getList()
            },
        }
    }
</script>

<style scoped="scoped">
    .ResultMore{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 15px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* tab按钮 */
    .btn{
       display: inline-block;
       border: 1px solid #999999;
       background-color: #FFFFFF;
       padding: 5px 15px;
       margin:0px 22px;
       margin-bottom: 10px;
       font-size: 14px;
       border-radius: 3px;
       cursor: pointer;
    }
    /* 选中样式 */
    .active{
        background: #26716C;
        color: #fff;
    }
    /* 成绩表表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 35px;
    }
    .ipt{
        border: 1px solid #CCC;
        width: 158px;
        height: 25px;
        background-color: #FFFFFF;
        font-size: 14px;
    }
    
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
